/* eslint-disable no-console */
import Api from "../../services/api";

const orders = {
  state: () => ({
    orders: [],
    perPage: 1,
    totalItems: 1,
    totalPages: 1,
    selectedOrder: {},
    viewOrder: {},
    selectedOrderMerchantId: null,
    orderCompany: {},
    orderCompanyWallet: {},
    nonUserOrderWallet: {}
  }),
  mutations: {
    SET_PER_PAGE(state, perPage) {
      state.perPage = perPage;
    },
    SET_TOTAL_ORDER_ITEMS(state, totalItems) {
      state.totalItems = totalItems;
    },
    SET_TOTAL_PAGES(state, pages) {
      state.totalPages = pages;
    },
    SET_ORDERS(state, payload) {
      state.orders = payload;
    },
    SET_VIEW_ORDER(state, payload) {
      state.viewOrder = payload;
    },
    SET_SELECTED_ORDER(state, payload) {
      state.selectedOrder = payload;
      state.selectedOrderMerchantId = payload.merchantId;
    },
    SET_SELECTED_ORDER_COMPANY(state, payload) {
      state.orderCompany = payload;
    },
    SET_SELECTED_ORDER_WALLETS(state, payload) {
      state.orderCompanyWallet = payload;
    },
    SET_NON_USER_ORDER_WALLETS(state, payload) {
      state.nonUserOrderWallet = payload;
      // console.log('SET_NON_USER_ORDER_WALLETS.payload:', payload);
    },
    CLEAR_NON_USER_ORDER_WALLETS(state) {
      state.nonUserOrderWallet = {};
    }
  },
  actions: {
    async GET_ORDERS({ getters, commit }, nextPage = 1) {
      const id = getters.MAIN_COMPANY_ID;

      const orders = await Api.get(
        "companies/" + id + "/company-orders",
        `?page=${nextPage}`,
        {
          headers: { Accept: "application/vnd.api+json" }
        }
      );

      const data = orders.data.data.map(item => {
        return item.attributes;
      });

      const totalItems = orders.data.meta.totalItems;
      const itemsPerPage = orders.data.meta.itemsPerPage;

      let totalPages = parseInt(totalItems / itemsPerPage);
      const remainder = totalItems % itemsPerPage;

      totalPages = remainder ? totalPages + 1 : totalPages;

      commit("SET_TOTAL_PAGES", totalPages);
      commit("SET_PER_PAGE", itemsPerPage);
      commit("SET_TOTAL_ORDER_ITEMS", totalItems);
      commit("SET_ORDERS", data);
      return data;
    },
    async DELETE_ORDER(state, orderId) {
      await Api.delete("/company-orders/" + orderId);
      state.commit("SET_VIEW_ORDER", {});
    },
    GET_ORDER_DETAILS(state, payload) {
      Api.post("order-detail", payload)
        .then(({ data }) => {
          state.commit("SET_SELECTED_ORDER_COMPANY", data.company);
          state.commit("SET_SELECTED_ORDER_WALLETS", data.wallets);
        })
        .catch(({ response }) => {
          console.error(response);
        });
    },
    UPDATE_SELECTED_QR_CURRENCY(state, payload) {
      Api.post("update-selected-currency", payload)
        .then(() => {
          console.log("UPDATE_SELECTED_QR_CURRENCY:success");
        })
        .catch(({ response }) => {
          console.error(response);
        });
    },
    CREATE_QR_SALE(state, payload) {
      return new Promise((resolve, reject) => {
        Api.post(
          "/company-orders/" + payload.orderId + "/transactions/qr-sale",
          payload,
          {
            headers: { "Content-Type": "application/json", Accept: "*/*" }
          }
        )
          .then(result => {
            resolve(result);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    },
    async CREATE_ORDER(state, payload) {
      payload.price = payload.price.toString();
      payload.fee = payload.fee.toString();

      return new Promise((resolve, reject) => {
        Api.post("company-orders", payload)
          .then(order => {
            resolve(order.data);
          })
          .catch(({ response }) => {
            console.error(response);
            reject(response);
          });
      });
    },
    GET_ORDER(state, payload) {
      // Api.get("company-orders/"+payload+'/')
      Api.get("company-orders/" + payload)
        .then(order => {
          state.commit("SET_ORDERS", order.data);
          state.commit("SET_SELECTED_ORDER", order.data);
        })
        .catch(({ response }) => {
          console.error(response);
        });
    },
    async SET_SELECTED_ORDER(state, payload) {
      await state.commit("SET_SELECTED_ORDER", payload);
    },
    async SET_VIEW_ORDER(state, payload) {
      await state.commit("SET_VIEW_ORDER", payload);
    },
    GET_NON_USER_WALLET(state, payload) {
      Api.get(
        `/nexus/create_payment/${payload.currency}/${payload.orderId}/${payload.payment}`
      )
        .then(order => {
          state.commit("SET_NON_USER_ORDER_WALLETS", order.data);
        })
        .catch(({ response }) => {
          console.error(response);
        });
    },
    CLEAR_NON_USER_WALLET(state) {
      state.commit("CLEAR_NON_USER_ORDER_WALLETS");
    },
    async CHECK_ORDER_STATUS(state, orderId) {
      return await Api.get(`company-orders/${orderId}`);
    }
  },
  getters: {
    TOTAL_ORDERS_PAGES(state) {
      return state.totalPages;
    },
    ORDERS_PER_PAGE(state) {
      return state.perPage;
    },
    ORDERS_TOTAL_ITEMS(state) {
      return state.totalItems;
    },
    MAIN_COMPANY_ID(state, getters, rootState, rootGetters) {
      return rootGetters["main/company_id"];
    },
    VIEW_ORDER(state) {
      return state.viewOrder;
    },
    SELECTED_ORDER(state) {
      return state.selectedOrder;
    },
    SELECTED_ORDER_MERCHANT_ID(state) {
      return state.selectedOrderMerchantId;
    }
  },

  namespaced: true
};

export default orders;
