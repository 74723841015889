<template>
  <div class="container pt-25">
    <div class="card card-custom example example-compact">
      <div class="card-header">
        <h3 class="card-title">
          <button
            type="button"
            @click="gotoCart"
            class="btn btn-bg-white btn-xs btn-icon"
          >
            <i class="la la-chevron-left text-dark"></i>
          </button>
          {{ $t("PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.TITLE") }}
        </h3>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center"></div>
        </div>
      </div>
      <form class="form" @submit.stop.prevent="onSubmit">
        <div class="card-body">
          <div class="row px-5">
            <div class="col-12 col-md-6">
              <h6>
                {{ $t("PAGES.SALES_MANAGEMENT.LINK_SALES.ORDER_INFO") }}
              </h6>
              <div class="col-12">
                <label class="col-form-label font-weight-bold"
                  >{{
                    $t("PAGES.SALES_MANAGEMENT.LINK_SALES.ORDER_IDENTITY")
                  }}:</label
                >
                <span class="ml-2">{{
                  selectedOrder.orderNumber ? selectedOrder.orderNumber : null
                }}</span>
              </div>

              <div class="col-12">
                <label class="col-form-label font-weight-bold"
                  >{{
                    $t("PAGES.SALES_MANAGEMENT.LINK_SALES.ORDER_AMOUNT")
                  }}:</label
                >
                <span class="ml-2">{{ selectedOrder.price }} $</span>
              </div>

              <div class="col-12">
                <label class="col-form-label font-weight-bold"
                  >{{
                    $t("PAGES.SALES_MANAGEMENT.LINK_SALES.COMMISSION_AMOUNT")
                  }}:</label
                >
                <span class="ml-2"
                  >{{ selectedOrder.fee }}% ({{ feeAmount }} $)</span
                >
              </div>

              <div class="col-12">
                <label class="col-form-label font-weight-bold">
                  {{ $t("PAGES.SALES_MANAGEMENT.LINK_SALES.TOTAL_AMOUNT") }}:
                  <!-- Komisyon Fiyata Dahil Mi? -->
                </label>
                <!-- <span class="ml-2" v-if="selectedOrder.feeIncluded">Evet</span>
                <span class="ml-2" v-else>Hayır</span> -->
                <span class="ml-2">{{ totalAmount }} $</span>
              </div>

              <div class="col-12">
                <label class="col-form-label font-weight-bold"
                  >{{
                    $t("PAGES.SALES_MANAGEMENT.LINK_SALES.TOTAL_QUANTITY")
                  }}:</label
                >
                <span class="ml-2"
                  >{{
                    selectedOrder.amount
                      ? selectedOrder.amount
                      : convertedCryptoAmount
                  }}
                  <template
                    v-if="
                      selectedCurrency &&
                        selectedCurrency.wallet &&
                        selectedCurrency.wallet.currencyCode
                    "
                  >
                    {{ selectedCurrency.wallet.currencyCode }}
                  </template>
                </span>
              </div>

              <div
                class="col-12"
                v-if="nonUserOrderWallet && nonUserOrderWallet.address"
              >
                <label class="col-form-label font-weight-bold"
                  >{{ $t("PAGES.SALES_MANAGEMENT.LINK_SALES.WALLET") }}:</label
                >
                <span class="ml-2">{{ nonUserOrderWallet.address }}</span>
              </div>

              <div class="col-12">
                <label class="col-form-label font-weight-bold"
                  >{{
                    $t("PAGES.SALES_MANAGEMENT.LINK_SALES.TRANSACTION_STATUS")
                  }}:</label
                >
                <span
                  class="ml-2 badge badge-warning"
                  v-if="selectedOrder.orderStatus === 'pending'"
                >
                  {{ $t("PAGES.SALES_MANAGEMENT.LIST.PENDING") }}
                </span>
                <span
                  class="ml-2 badge badge-info"
                  v-if="selectedOrder.orderStatus === 'proccessing'"
                >
                  {{ $t("PAGES.SALES_MANAGEMENT.LIST.PROCESSING") }}
                </span>
                <span
                  class="badge badge-success"
                  v-if="selectedOrder.orderStatus === 'completed'"
                >
                  {{ $t("PAGES.SALES_MANAGEMENT.LIST.COMPLETED") }}
                </span>
              </div>
            </div>

            <!-- <div class="col-12 col-md-6">
              <h6>Müşteri Bilgileri</h6>
              <div class="d-inline-flex col-12 align-items-center">
                <label class="col-form-label font-weight-bold">
                  {{ selectedOrder.customerName }}
                </label>
              </div>

              <div class="roe">
                <div class="d-inline-flex col-12 col-md-6 align-items-center">
                  <i class="menu-icon flaticon2-phone text-primary"></i>
                  <label class="col-form-label ml-2">
                    {{ selectedOrder.customerPhone }}
                  </label>
                </div>

                <div
                  v-if="selectedOrder.customerMail"
                  class="d-inline-flex col-12 col-md-6 align-items-center"
                >
                  <i class="menu-icon flaticon2-email text-primary"></i>
                  <label class="col-form-label ml-2">
                    {{ selectedOrder.customerMail }}
                  </label>
                </div>
              </div>
            </div> -->
          </div>
        </div>

        <div
          class="card-footer d-md-flex flex-md-wrap align-items-center justify-content-between"
        >
          <currency-selector
            v-on:currency-selected="currencySelected"
            :currencies="orderCompanyWallet"
            :selectedCurrency="selectedCurrency"
          ></currency-selector>
          <div class="col-12 col-md-4 mt-md-0 mt-4">
            <span class="form-control">USD</span>
          </div>
          <qr-code-reader
            :disabled="!selectedCurrency"
            v-on:qrcode-scanned="QRCodeScanned"
          ></qr-code-reader>
          <div class="col-12 col-md-4 mt-md-0 mt-4">
            <b-button
              variant="outline-success"
              block
              :disabled="!selectedCurrency"
              @click="nonUserContinue"
            >
              <span>{{
                $t(
                  "PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.NOT_MIRACLE_CUSTOMER"
                )
              }}</span>
            </b-button>
          </div>
        </div>
      </form>
    </div>

    <!-- qrcode awaiting modal -->
    <b-modal
      v-model="isQrScanning"
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      hide-header
    >
      <template #modal-title> </template>
      <b-alert show variant="info" class="pt-7">
        <div class="d-flex justify-content-center mb-3">
          <b-spinner label="Loading..." type="grow"></b-spinner>
        </div>
        <div class="d-flex justify-content-center mb-3">
          {{ $t("AUTH.LOGIN.LOADING_MESSAGE_TITLE") }}...
        </div>
      </b-alert>
    </b-modal>

    <b-modal
      v-model="showQrResult"
      no-close-on-esc
      no-close-on-backdrop
      id="qrcodeAwaitingModal"
    >
      <template #modal-title>
        <span v-if="qrSuccess">{{
          $t("PAGES.SALES_MANAGEMENT.LINK_SALES.TRANSACTION_SUCCESS")
        }}</span>
        <span v-else>{{
          $t("PAGES.SALES_MANAGEMENT.LINK_SALES.TRANSACTION_ERROR")
        }}</span>
      </template>
      <b-alert show :variant="variant">
        <h3 class="m-0 py-3">
          <strong
            class="
              d-flex
              flex-1 flex-column
              align-items-center
              justify-content-center
            "
            v-if="qrSuccess"
          >
            <span>{{
              $t(
                "PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.SUCCESS_TITLE"
              )
            }}</span>
            <span class="mt-3">{{
              $t(
                "PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.SUCCESS_SUBTITLE"
              )
            }}</span>
          </strong>
          <strong
            class="
              d-flex
              flex-1 flex-column
              align-items-center
              justify-content-center
            "
            v-else
          >
            <span>{{
              $t(
                "PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.AN_ERROR_HAS_OCCURRED"
              )
            }}</span>
            <span class="mt-2">{{
              $t(
                "PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.ERROR_SUBTITLE"
              )
            }}</span>
            <small class="d-block mt-4 font-size-small">{{
              qrErrorMessage
            }}</small>
          </strong>
        </h3>
      </b-alert>
      <h5 class="mt-4 text-center" v-if="qrSuccess && paymentSuccessAmount">
        {{ paymentSuccessAmount }}
      </h5>
      <template #modal-footer>
        <div class="d-flex flex-1">
          <div v-if="!qrSuccess" class="d-inline-flex">
            <b-button
              class="d-inline-flex justify-self-start"
              variant="warning"
              @click="tryAgain"
              >{{
                $t(
                  "PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.TRY_AGAIN"
                )
              }}</b-button
            >
          </div>
          <div class="d-inline-flex flex-1 justify-content-end">
            <b-button class="mr-3" variant="primary" @click="gotoCart">{{
              $t("cart")
            }}</b-button>
          </div>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="nonUser"
      v-model="nonUserModalShow"
      centered
      hide-footer
      modal-class="body-class text-center"
      @show="onNonUserModalShow"
      @hide="onNonUserModalHide"
    >
      <template #modal-header="{ close }">
        <h2 class="m-0 w-100 text-primary w-100 text-center font-weight-bolder">
          &nbsp;
        </h2>
        <button type="button" aria-label="Close" @click="close()" class="close">
          ×
        </button>
      </template>

      <!-- <template v-if="nonUserOrderWallet"> -->
      <template v-if="nonUserOrderWallet.address">
        <!-- <img v-if="nonUserOrderWallet.wallet" class="img-fluid" :src="'https://chart.googleapis.com/chart?chs=400x400&cht=qr&chld=L&chl='+nonUserOrderWallet.wallet" alt=""> -->
        <img
          v-if="nonUserOrderWallet.address"
          class="img-fluid"
          :src="
            'https://chart.googleapis.com/chart?chs=400x400&cht=qr&chld=L&chl=' +
              nonUserOrderWallet.address
          "
          alt=""
        />
        <!-- <h3 v-if="nonUserOrderWallet.amount">{{nonUserOrderWallet.amount}}</h3> -->
        <h3
          v-if="
            selectedCurrency &&
              selectedCurrency.wallet &&
              selectedCurrency.wallet.currencyCode
          "
        >
          {{ selectedCurrency.wallet.currencyCode }}
        </h3>
        <h3 v-if="selectedOrder.amount">{{ selectedOrder.amount }}</h3>
        <h6 v-if="nonUserOrderWallet.address">
          {{ nonUserOrderWallet.address }}
        </h6>
      </template>
      <template v-else>
        <!-- <b-icon
            icon="gear"
            font-scale="8"
            animation="spin"
            class="mb-4"
        ></b-icon> -->
        <b-icon
          icon="three-dots"
          font-scale="8"
          animation="cylon"
          class="mb-4"
        ></b-icon>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import CurrencySelector from "@/components/currency/CurrencySelector.vue";
import QrCodeReader from "@/components/QrCode/QrCodeReader.vue";

export default {
  components: { CurrencySelector, QrCodeReader },
  data() {
    return {
      orderCompanyWallet: [],
      selectedCurrency: null,
      startScan: false,
      qrCode: null,
      showQrResult: false,
      variant: null,
      qrSuccess: false,
      qrErrorMessage: null,
      totalAmount: 0,
      feeAmount: 0,
      refreshInterval: null,
      nonUserModalShow: false,
      isQrScanning: false,
      selectedPaymentUnit: null,
      paymentSuccessAmount: "",
      checkOrderStatusInterval: null,
      convertedCryptoAmount: ""
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("ROUTES.SALES_MANAGEMENT") },
      { title: this.$t("ROUTES.QR_SALE") }
    ]);
    this.redirectToHome();
    this.$store.dispatch("paymentunits/GET_PAYMENT_UNITS");

    const price = this.selectedOrder?.price
      ? parseFloat(this.selectedOrder.price)
      : 0;
    const fee = this.selectedOrder?.fee
      ? parseFloat(this.selectedOrder.fee)
      : 0;
    const isFeeIncludedPrice = this.selectedOrder.feeIncluded;

    this.feeAmount = (price * fee) / 100;
    this.totalAmount = isFeeIncludedPrice
      ? price - this.feeAmount
      : price + this.feeAmount;
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      if (modalId === "qrcodeAwaitingModal") {
        this.gotoCart();
      }
    });
  },
  created() {
    this.getWallets(true).then(wallets => (this.orderCompanyWallet = wallets));
  },
  destroyed() {
    this.CLEAR_NON_USER_WALLET();
    if (this.checkOrderStatusInterval) {
      clearInterval(this.checkOrderStatusInterval);
    }
  },
  computed: {
    ...mapState({
      selectedOrder: state => state.main.order,
      nonUserOrderWallet: state => state.orders.nonUserOrderWallet,
      paymentUnitsLoading: state => state.paymentunits.loading,
      paymentUnits: state => state.paymentunits.data
    })
  },
  methods: {
    ...mapActions({
      UPDATE_SELECTED_QR_CURRENCY: 'orders/"UPDATE_SELECTED_QR_CURRENCY',
      CREATE_QR_SALE: "orders/CREATE_QR_SALE",
      getWallets: "wallets/GET_COMPANY_WALLETS",
      GET_NON_USER_WALLET: "orders/GET_NON_USER_WALLET",
      CLEAR_NON_USER_WALLET: "orders/CLEAR_NON_USER_WALLET",
      CHECK_ORDER_STATUS: "orders/CHECK_ORDER_STATUS"
    }),
    currencySelected(currency) {
      this.selectedCurrency = currency;
      this.selectedOrder["selectedCurrencyId"] = currency.id;
      this.selectedOrder["selectedCurrencyCode"] = currency.wallet.currencyCode;
      // this.$store.dispatch("orders/UPDATE_SELECTED_QR_CURRENCY", {
      //   id: this.selectedOrder.id,
      //   selected_currency_id: currency.currency_code
      // });
      if (currency?.wallet?.currencyCode) {
        this.convertCurrencyToCrypto(currency.wallet.currencyCode);
      }
    },
    tryAgain() {
      this.showQrResult = false;
    },
    QRCodeScanned(qrCode) {
      // valid: e2130b297f605aedb30020158c935830b34a23028b8de065ec5b0b4987faf6fb

      if (qrCode) {
        this.isQrScanning = true;
        this.$store
          .dispatch("orders/CREATE_QR_SALE", {
            orderId: this.selectedOrder?.id
              ? this.selectedOrder.id
              : this.selectedOrder._id,
            selectedCurrencyId: this.selectedOrder.selectedCurrencyId,
            selectedCurrencyCode: this.selectedOrder.selectedCurrencyCode,
            hash: qrCode,
            selectedPaymentName: "usd"
          })
          .then(result => {
            this.qrSuccess = result.status === 200;

            this.variant = this.qrSuccess ? "success" : "danger";
            this.qrErrorMessage = this.qrSuccess
              ? null
              : result.data?.message || this.$t("unexpected_error");
            if (this.qrSuccess) {
              const { cyrpto_amount, cyrpto } = result.data.content;
              this.paymentSuccessAmount = `${cyrpto_amount || ""} ${cyrpto ||
                ""}`;
            }

            this.qrCode = qrCode;
            this.showQrResult = true;
            this.isQrScanning = false;
          })
          .catch(err => {
            this.qrSuccess = false;
            this.variant = "danger";
            this.qrErrorMessage =
              err?.response?.data?.message || this.$t("FORM.ERROR");
            this.qrCode = qrCode;
            this.showQrResult = true;
            this.isQrScanning = false;
          });
      }
    },
    gotoCart() {
      this.$router.replace({ name: "cart" });
    },
    redirectToHome() {
      const order = Object.keys(this.selectedOrder).length > 0;
      if (!order) this.$router.push("/");
    },
    nonUserContinue() {
      this.nonUserModalShow = true;
      const params = {
        currency: this.selectedCurrency.wallet.currencyCode,
        orderId: this.selectedOrder?.id
          ? this.selectedOrder.id
          : this.selectedOrder._id,
        payment: "usd"
      };
      this.GET_NON_USER_WALLET(params);
      // console.log("nonUserContinue");
    },
    onNonUserModalShow() {
      const self = this;
      this.checkOrderStatusInterval = setInterval(function() {
        const orderId = self.selectedOrder?.id
          ? self.selectedOrder.id
          : self.selectedOrder._id;
        self.CHECK_ORDER_STATUS(orderId).then(res => {
          const {
            orderStatus,
            nexusAmount,
            nexusAmountConvertCurrency
          } = res.data;
          const isCompleted = orderStatus === "completed";
          const isNotEnough = orderStatus === "notEnough";
          const bodyHtml = `<div class="w-100">
              <div class="col-12">
                <label class="col-form-label font-weight-bold"
                  >${self.$t(
                    "PAGES.SALES_MANAGEMENT.QR_SALE.ORDER_AMOUNT_IS"
                  )}:</label
                >
                <span class="ml-2">${nexusAmountConvertCurrency ||
                  "0"} USD</span>
              </div>
              <div class="col-12">
                <label class="col-form-label font-weight-bold"
                  >${self.$t(
                    "PAGES.SALES_MANAGEMENT.QR_SALE.ORDER_CRYPTO_AMOUNT_IS"
                  )}:</label
                >
                <span class="ml-2">${nexusAmount ||
                  "0"} ${self.selectedCurrency?.wallet?.currencyCode?.toUpperCase()}</span>
              </div>
            </div>`;
          // console.log("is order completed: ", isCompleted);
          if (isCompleted) {
            clearInterval(self.checkOrderStatusInterval);
            Swal.fire({
              icon: "success",
              title: self.$t("PAGES.SALES_MANAGEMENT.QR_SALE.ORDER_COMPLETED"),
              html: bodyHtml,
              confirmButtonText: self.$t("FORM.OK"),
              allowOutsideClick: false
            });
          }
          if (isNotEnough) {
            clearInterval(self.checkOrderStatusInterval);
            Swal.fire({
              icon: "warning",
              title: self.$t(
                "PAGES.SALES_MANAGEMENT.QR_SALE.MISSING_ORDER_PAYMENT"
              ),
              html: bodyHtml,
              confirmButtonText: self.$t("FORM.OK"),
              allowOutsideClick: false
            });
          }
        });
      }, 5000);
    },
    onNonUserModalHide() {
      // console.log("onNonUserModalHide: ", this.selectedOrder);
      if (this.checkOrderStatusInterval) {
        clearInterval(this.checkOrderStatusInterval);
      }
    },
    async convertCurrencyToCrypto(crypto) {
      try {
        const res = await this.$store.dispatch("calculator/MPAY_CONVERT", {
          crypto,
          currency: "usd",
          amount: this.totalAmount
        });
        this.convertedCryptoAmount = res.data?.crypto_amount || "";
      } catch (error) {
        console.log(error.response?.data?.message || "");
      }
    }
  }
};
</script>

<style lang="scss">
.font-size-small {
  font-size: small;
}
</style>
