<template>
  <div class="container pt-10 pb-5">
    <div class="d-flex justify-content-center align-items-center w-100">
      <span v-if="isLoading">Loading...</span>
      <template v-else>
        <div class="d-flex flex-col" v-if="error">
          <span class="text-danger font-weight-bolder mb-4">{{ error }}</span>
          <b-button @click="initIframe">Refresh</b-button>
        </div>
        <iframe
          v-else
          style="width: 100%; min-height: 800px; border: none"
          :src="iframeUrl"
          id="mpayIframe"
        ></iframe>
      </template>
    </div>
  </div>
</template>
<script>
import Axios from "axios";

export default {
  name: "iframe-simulation",
  components: {},
  data: () => ({
    apiBaseUri: "https://api.miraclepay.nl",
    form: {
      email: "cagdas+3424@basefy.com",
      password: "999999"
    },
    isLoading: false,
    iframeUrl: "",
    iframeBaseUrl: "https://panel.miraclepay.nl",
    error: null,
    authToken: null
  }),
  methods: {
    handleAuth() {
      const authToken = this.getTokenFromStorage();
      if (authToken) {
        this.iframeUrl = `${this.iframeBaseUrl}/pay-sdk?token=${authToken}&order_price=5&customer_name=IframeTest`;
      } else {
        this.initIframe();
      }
    },
    async initIframe() {
      this.isLoading = true;
      this.error = null;
      try {
        // Replace with your actual API endpoint that provides the token
        const response = await Axios.post(
          `${this.apiBaseUri}/authentication_token`,
          this.form
        );
        if (!response.data.token) {
          throw "Error fetching the authentication token";
        }
        const expirationTime = Date.now() + 30 * 60 * 1000; // Token expires in 30 minutes

        window.localStorage.setItem(
          "authToken",
          JSON.stringify({ expirationTime, token: response.data.token })
        );
        this.iframeUrl = `${this.iframeBaseUrl}/pay-sdk?token=${response.data.token}&order_price=5&customer_name=IframeTest`;
      } catch (err) {
        this.error = "Error while trying to authenticate.";
        throw err;
      } finally {
        this.isLoading = false;
      }
    },
    getTokenFromStorage() {
      const tokenData = JSON.parse(localStorage.getItem("authToken"));
      if (tokenData) {
        // Check if the token has expired
        const currentTime = Date.now();
        if (currentTime < tokenData.expirationTime) {
          return tokenData.token; // Return the token if it hasn't expired
        } else {
          // If token is expired, remove it from storage
          localStorage.removeItem("authToken");
        }
      }
      return null; // No valid token found
    },

    async copyAddress(text) {
      await navigator.clipboard.writeText(text);
      this.$root.$bvToast.toast("Copied to clipboard.", {
        title: this.$t("COMMON.INFORM"),
        variant: "info",
        autoHideDelay: 5000
      });
    },
    handleIframeMessage(event) {
      switch (event.data.event) {
        case "onCopyWalletAddress":
          if (event.data.data) {
            this.copyAddress(event.data.data);
          }
          break;
        case "onGetNexusPaymentAddress":
          if (event.data.data) {
            window.localStorage.setItem("_wa_d", event.data.data);
          }
          break;
        default:
          return;
      }
    },
    async performDeleteWallet() {
      const walletAddress = window.localStorage.getItem("_wa_d");
      if (walletAddress) {
        // post request to delete wallet address endpoint
        try {
          await Axios.post(
            `${this.apiBaseUri}/api/delete-wallet-address`,
            {
              wallet_address: walletAddress
            },
            {
              headers: {
                Authorization: `Bearer ${this.getTokenFromStorage()}`
              }
            }
          );
          window.localStorage.removeItem("_wa_d");
        } catch (error) {
          console.error(error);
        }
      }
    }
  },
  mounted() {
    this.performDeleteWallet();
    this.handleAuth();
    window.addEventListener("message", this.handleIframeMessage);
  },
  beforeDestroy() {
    // Remove the event listener when the component is destroyed
    window.removeEventListener("message", this.handleIframeMessage);
  }
};
</script>
