import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/cart",
      component: () => import("@/view/layout/CommonLayout"),
      children: [
        {
          path: "/cart",
          name: "cart",
          component: () => import("@/view/pages/Cart.vue")
        },
        {
          path: "/qr-sale",
          name: "qr-sale",
          component: () => import("@/view/pages/QrSale.vue")
        }
      ]
    },
    {
      path: "/iframe-simulation",
      name: "iframe-simulation",
      component: () => import("../view/pages/IframeSimulation.vue")
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/Error.vue")
    }
  ]
});
