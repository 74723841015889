// USA
export const locale = {
  TRANSLATOR: {
    SELECT: "Select your language"
  },
  SLOGAN: "New Way of Payment Collecting",
  MENU: {
    NEW: "New",
    EDIT: "Edit",
    ACTIONS: "Actions",
    CREATE_POST: "Create New Post",
    PAGES: "Pages",
    FEATURES: "Features",
    APPS: "Apps",
    GREET: "Hello",
    BACK: "Back"
  },
  ROUTES: {
    DASHBOARD: "Dashboard",
    HOME: "Home",
    SALES_MANAGEMENT: "Sales Management",
    ALL_SALES: "All Sales",
    NEW_SALE: "New Sale",
    QR_SALE: "New Sale with QR Code",
    SALE_DETAILS: "Sale Details",
    WALLET_MANAGEMENT: "Wallet Management",
    WALLETS: "Wallets",
    WITHDRAWALS: "Withdrawals",
    PERSONNEL_MANAGEMENT: "Personnel Management",
    PERSONNEL: "Tüm Personel",
    NEW_PERSONNEL: "New Personnel",
    USERS: "Users",
    PROFILE: "Profile",
    COMPANY: "Company",
    SETTINGS: "Settings",
    ROLES: "Roles",
    LOGS: "Logs",
    LOGOUT: "Logout",
    CHANGE_PASSWORD: "Change Password"
  },
  FORM: {
    ID: "Id",
    YES: "Yes",
    NO: "No",
    OK: "OK",
    TRY_AGAIN: "Try Again",
    SAVE: "Save",
    UPDATE: "Update",
    SUBMIT: "Submit",
    CANCEL: "Cancel",
    CONFIRM: "Confirm",
    EMAIL: "Email",
    FIRST_NAME: "Name",
    LAST_NAME: "Family name",
    FULL_NAME: "Name familyname",
    PASSWORD: "Password",
    PASSWORD_CONFIRM: "Password Confirmation",
    PHONE: "Phone",
    LOGIN_WITH_GOOGLE: "Login with Google Two Factor Authentication",
    GOOGLE_SCAN_MESSAGE1: "Scan QR Code through",
    GOOGLE_SCAN_MESSAGE2: "",
    CLICK: "Click",
    WHEN_DONE: "when done",
    PHONE_OR_EMAIL: "Phone or Email",
    CONFIRM_PASSWORD: "Confirm Password",
    USERNAME: "Username",
    SUCCESS_TITLE: "Successful Operation",
    SUBMITTED: " successfully saved",
    UPDATED: " successfully updated",
    DELETED: " successfully deleted",
    ERROR_TITLE: "Error",
    ERROR: "An Error Occured.",
    PLEASE_SELECT: "Select an option",
    PAYMENT_UNIT: "Payment Unit",
    POS_NUMBER: "Pos Number",
    SELECT_A_PAYMENT_UNIT: "Select a payment unit",
    SELECT_A_CURRENCY_UNIT: "Select a currency unit"
  },
  AUTH: {
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact"
    },
    LOGIN: {
      TITLE: "Login Account",
      BUTTON: "Sign In",
      WELCOME: "Welcome",
      NEW_HERE: "New Here?",
      CREATE_ACCOUNT: "Create New Account",
      LOGIN: "Login",
      OTP_VERIFICATION: "OTP Verification",
      TWO_FA: "Two Factor Authentication",
      TWO_FA_GOOGLE: "Two Factor Authentication with Google",
      TWO_FA_MESSAGE:
        "Please enter the code sent to your phone or email to login",
      TWO_FA_GOOGLE_MESSAGE:
        "Please enter the code from Google Authenticator app to login",
      VERIFICATION_CODE: "Enter verification code",
      SEND_AGAIN: "Send Again",
      LOADING_MESSAGE_TITLE: "Hang On",
      LOADING_MESSAGE_SUBTITLE: "Gathering Your Info..."
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset."
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      AGREEMENT_TEXT: "I Agree the",
      AGREEMENT_LINK: "terms and conditions",
      SUCCESS: "Your account has been successfuly registered."
    },
    VALIDATION: {
      INVALID: "{name} is not valid",
      REQUIRED: "{name} is required",
      MIN_LENGTH: "{name} minimum length is {min}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {name} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid",
      PASSWORD_DONT_MATCH: "Passwords do not match."
    }
  },
  LIST: {
    CREATED_AT: "Created at",
    UPDATED_AT: "Updated at"
  },
  USERS: {
    DELETED_USERS: "Deleted Users",
    NEW_USER: "New User"
  },
  COMPANY: {
    COMPANY: "Company",
    USER: "User",
    TITLE: "Title",
    TAX_ADMINISTRATION: "Tax Administration",
    TAX_CITY: "Tax Office City",
    TAX_NUMBER: "Tax Number",
    QUICK_SALE: "Quick Sale",
    MAIL_INFO_MESSAGE: "Generated user credentials will be sent to this email."
  },
  PAGES: {
    SALES_MANAGEMENT: {
      ORDER_DETAILS: {
        SALE_DETAILS: "Sale Details",
        DELETE_ORDER: "Delete Order",
        ORDER_INFOS: "Order Informations",
        ORDER_ID: "Order ID"
      },
      LINK_SALES: {
        NEW_SALE: "New Sale with Link",
        ORDER_INFO: "Order Info",
        ORDER_AMOUNT: "Order Amount",
        COMMISSION_RATE: "Commission Rate",
        DEDUCT_COMMISSION: "Deduct Commission from Order Amount?",
        COMMISSION_AMOUNT: "Commission Amount",
        ORDER_TOTAL: "Order Total",
        CREATE_LINK: "Create Link",
        ORDER_IDENTITY: "Order Identity",
        TOTAL_AMOUNT: "Total Amount",
        TOTAL_QUANTITY: "Total Quantity",
        WALLET: "Wallet",
        TRANSACTION_STATUS: "Transaction Status",
        TRANSACTION_SUCCESS: "Transaction Success",
        TRANSACTION_ERROR: "Transaction Error"
      },
      LIST: {
        ORDER_ID: "Order id",
        PRICE: "Price",
        COMMISSION: "Comission",
        TOTAL: "Total",
        STATUS: "Status",
        DATE: "Date",
        SALE: "Sale",
        PENDING: "Pending",
        COMPLETED: "Completed",
        PROCESSING: "Processing",
        FAILED: "Failed",
        CANCELLED: "Cancelled",
        NOT_ENOUGH: "Not Enough"
      },
      QR_SALE: {
        NEW_SALE_WITH_QR_CODE: {
          TITLE: "New Sale with QR Code",
          CUSTOMER_INFO: "Customer Info",
          SUCCESS_TITLE: "Payment Received.",
          SUCCESS_SUBTITLE: "Thank you.",
          ERROR_SUBTITLE: "Please Try Again.",
          SCAN_QR_CODE: "Scan QR Code",
          MIRACLE_CUSTOMER: "Miracle Customer",
          NOT_MIRACLE_CUSTOMER: "Not Miracle Cust.",
          AN_ERROR_HAS_OCCURRED: "An error has occurred.",
          TRY_AGAIN: "Try Again",
          ALL_ORDERS: "All Orders",
          NEW_ORDER: "New Order"
        },
        ORDER_COMPLETED: "Order completed.",
        ORDER_AMOUNT_IS: "Transaction amount",
        MISSING_ORDER_PAYMENT: "Missing order payment has been made.",
        ORDER_CRYPTO_AMOUNT_IS: "Crypto Amount"
      },
      PROFILE: {
        PROFILE_PAGE: "Profile Page"
      }
    },
    DASHBOARD: {
      CRYPTO: "Crypto",
      CURRENCY: "Currency",
      LATEST_TRANSACTIONS: "Latest Transactions",
      CRYPTO_AMOUNT: "Amount"
    }
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields"
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers list",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted"
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted"
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated"
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created"
      }
    }
  },
  VALIDATION: {
    NUMERIC: "Accepts only numeric values.",
    MIN_VALUE: "{name} minimum value must be {min}",
    ONLY_INTEGERS: "Only integers are accepted."
  },
  COMMON: {
    ACTIVATE: "Activate",
    MAKE_PASSIVE: "Make Passive",
    NO_DATA_AVAILABLE: "Veri bulunamadı."
  },
  cart: "Cart",
  price: "Price",
  total: "Total",
  pay_with_crypto: "Pay With Crypto",
  unexpected_error: "An unexpected error has occured."
};
