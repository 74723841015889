<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="
        login login-1
        d-flex
        flex-column flex-lg-row flex-column-full-fluid
        bg-white
        login-signin-on
      "
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #2980b9"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <h3
            class="font-weight-bolder text-center font-size-h4 font-size-h1-lg"
            style="color: white"
          >
            MiraclePay
            <br />
            {{ $t("SLOGAN") }}
          </h3>
        </div>
        <div
          class="
            aside-img
            d-flex
            flex-row-fluid
            bgi-no-repeat bgi-position-y-bottom bgi-position-x-center
          "
          :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="
          login-content
          flex-row-fluid
          d-flex
          flex-column
          justify-content-center
          position-relative
          overflow-hidden
          p-7
          mx-auto
        "
      >
        <!--begin: Language bar -->
        <div class="text-right">
          <b-dropdown
            size="sm"
            variant="link"
            toggle-class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 text-decoration-none"
            no-caret
            right
            no-flip
          >
            <template v-slot:button-content>
              <img
                class="h-20px w-20px rounded-sm"
                :src="languageFlag || getLanguageFlag"
                alt=""
              />
            </template>
            <b-dropdown-text tag="div" class="min-w-md-175px">
              <KTDropdownLanguage
                v-on:language-changed="onLanguageChanged"
              ></KTDropdownLanguage>
            </b-dropdown-text>
          </b-dropdown>
        </div>
        <!--end: Language bar -->
        <div class="d-flex flex-center">
          <h3
            class="
            font-weight-bolder
            text-dark
            font-size-h4 font-size-h1-lg
          "
          >
            {{ $t("cart") }}
          </h3>
        </div>
        <div class="d-flex flex-center flex-wrap">
          <!--begin::Products-->
          <b-list-group class="w-100">
            <b-list-group-item
              class="d-flex justify-content-between align-items-center"
              v-for="(product, idx) in products"
              :key="idx"
            >
              <b-img-lazy
                :src="product.image"
                alt="Image"
                v-bind="{ width: 100, height: 100 }"
              >
              </b-img-lazy>
              {{ product.name }}
              <b-badge variant="primary" pill
                >{{ $t("price") }}: {{ product.price }} $</b-badge
              >
            </b-list-group-item>
          </b-list-group>
          <!--end::Products-->
        </div>
        <div class="d-flex flex-row-reverse">
          <span class="font-weight-bolder font-size-lg my-2"
            >{{ $t("total") }}: 12.12 $</span
          >
        </div>
        <div class="form-group d-flex flex-wrap pb-lg-0 flex-row-reverse">
          <button
            type="button"
            ref="kt_login_signin_submit"
            @click="handleSubmit"
            class="
                  btn btn-primary
                  font-weight-bolder font-size-h6
                  px-8
                  py-4
                  my-3
                "
          >
            {{ $t("pay_with_crypto") }}
            <i class="la la-chevron-right"></i>
          </button>
        </div>
        <!--begin::Content footer-->
        <div
          class="
            d-flex
            justify-content-lg-start justify-content-center
            align-items-end
            py-7 py-lg-0
          "
        >
          <!--
          <a href="#" class="text-primary font-weight-bolder font-size-h5"
            >Terms</a
          >
          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5"
            >Plans</a
          >
          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5"
            >Contact Us</a
          >
          -->
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
/* eslint-disable no-console */
import { mapGetters, mapState } from "vuex";
import KTDropdownLanguage from "@/view/layout/extras/dropdown/DropdownLanguage.vue";
import i18nService from "@/services/i18n.js";

export default {
  name: "card",
  components: {
    KTDropdownLanguage
  },
  data() {
    return {
      form: {
        password: "123456",
        email: "other@branch.com"
      },
      products: [
        {
          id: 1,
          name: "Apple Watch",
          price: "4.04",
          image: process.env.BASE_URL + "media/products/1.png"
        },
        {
          id: 2,
          name: "Apple Airpods 2. Gen",
          price: "4.04",
          image: process.env.BASE_URL + "media/products/21.png"
        },
        {
          id: 3,
          name: "Nike Sneaker",
          price: "4.04",
          image: process.env.BASE_URL + "media/products/10.png"
        }
      ],
      orderData: {
        price: "12.12",
        feeIncluded: false,
        fee: "3",
        customerName: "",
        customerMail: "",
        customerPhone: ""
      },
      languageFlag: "",
      languages: i18nService.languages
    };
  },
  computed: {
    ...mapState({
      authToken: state => state.main.token
    }),
    ...mapGetters({
      company: "main/company"
    }),
    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-2.svg"
      );
    },
    getLanguageFlag() {
      return this.onLanguageChanged();
    }
  },
  mounted() {},
  methods: {
    async handleSubmit() {
      this.$store.commit("main/PURGE_AUTH");
      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      try {
        await this.$store.dispatch("main/authenticationToken", this.form);
        await this.$store.dispatch("main/createOrder", this.orderData);
      } catch (error) {
        console.log(error);
      } finally {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }
    },
    onLanguageChanged() {
      this.languageFlag = this.languages.find(val => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    }
  }
};
</script>
